import React, { useEffect, useState } from 'react';
import "antd/dist/antd.css";
import './App.css';
import Login from "./Pages/Login";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import SignUp from './Pages/SignUp';
import Cashback from './Pages/Cashback';
function App() {
  const [auth, setAuth] = useState(false)
  let user = JSON.parse(window.localStorage.getItem("user"))
  useEffect(() => {
    if (user) {
        setAuth(user.id)
    }  
    
  }, [])
  return (
    <BrowserRouter>
      <div className={auth ? "App_back": "App"}>
        <Switch>
          <Route exact path="/">
              {auth ? <Cashback user={user}></Cashback> : <Login ></Login>}
          </Route>
          <Route exact path="/signup">
              <SignUp></SignUp>
          </Route>
        </Switch>       
      </div>
    </BrowserRouter>
  );
}

export default App;
