import React, { useEffect, useState } from "react";
import FetchApi from "../api/FetchApi";
import Avatar from "../components/Avatar";
import doorOpen from "../images/door-open-fill.png";
const Cashback = ({ user }) => {
  const [cashback, setCashback] = useState(0);
  useEffect(() => {
    getCash();
    setInterval(getCash, 5000);
  }, []);

  const getCash = async () => {
    const res = await FetchApi(`/cashback/${user.id}`, "GET");
    if (res && res.status == 200) {
      if (res.data.length > 0) {
        setCashback(res.data[0].bonus);
      }
    }
  };
  function numberFormat(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } else return x;
  }

  const leave = () => {
    window.localStorage.removeItem("user");
    window.location.reload();
  };
  return (
    <div className="cashback">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3 style={{ color: "white", textAlign: "left" }}>+{user.telefon}</h3>{" "}
        <h3>
          <img
            onClick={leave}
            style={{ cursor: "pointer" }}
            src={doorOpen}
            alt="door open"
          />
        </h3>
      </div>
      <div className="cashback_area">
        <h3 style={{ color: "var(--main-color)", fontWeight: "550" }}>
          {user.fam_ism}
        </h3>
        <h3 style={{ color: "#80A3E6", fontWeight: "500", margin: "0" }}>
          Балл
        </h3>
        <h1 style={{ color: "var(--main-color)", fontWeight: "bold" }}>
          {numberFormat(cashback)}
        </h1>
        <div className="avatar">
          <Avatar user={user}></Avatar>
        </div>
      </div>
    </div>
  );
};

export default Cashback;
