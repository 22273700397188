import React from "react";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import avatar from "../images/avatar.svg";
import { BaseUrl } from "../BaseUrl";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Faqatgina JPG/PNG formatdagi rasmlarni yuklang");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Rasm 2MBdan kam bo'lishi kerak!");
  }
  return isJpgOrPng && isLt2M;
}

class Avatar extends React.Component {
  state = {
    loading: false,
  };

  handleChange = info => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  componentDidMount() {
    if (this.props.user.img) {
      this.setState({
        ...this.state,
        imageUrl: `${BaseUrl}/${this.props.user.img}`,
      });
    }
  }
  render() {
    const { loading, imageUrl } = this.state;
    return (
      <Upload
        name="avatar"
        listType="picture"
        style={{ borderRadius: "50%" }}
        className="avatar-uploader"
        showUploadList={false}
        action={`${BaseUrl}/photo/${this.props.user.id}`}
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            style={{ width: "100%", borderRadius: "50%" }}
          />
        ) : loading ? (
          <LoadingOutlined style={{ color: "white", fontSize: "30px" }} />
        ) : (
          <img src={avatar}></img>
        )}
      </Upload>
    );
  }
}

export default Avatar;
